<!-- ***************************************
TEMPLATE: Contact Card
*************************************** -->
<nb-layout>

    <nb-layout-column>

        <!-- LOGIN CARD -->
        <div class="app-login-card">
            <nb-card>
                <!-- CARD HEADER -->
                <nb-card-header class="card-background-color">
                    <h1 class="app-title-1 text-center">{{appName}}</h1>
                </nb-card-header>

                <!-- CARD BODY -->
                <nb-card-body>

                    <div class="text-center">
                        <small>a demo app by</small>
                        <h4 class="author-text">Cliff Crerar</h4>
                    </div>

                    <div class="text-center">
                        <img class="img-fluid" src="https://cdn-cloudflare.ga/assets/own-logo/logotiny.png" alt="app-logo">
                    </div>

                </nb-card-body>

                <!-- CARD FOOTER -->
                <nb-card-footer class="text-center">
                    <button [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="large" nbButton hero status="primary" (click)="enterSetCookie()">
                    <nb-icon icon="log-out-outline"></nb-icon>Enter
                    </button>
                </nb-card-footer>

            </nb-card>
        </div>

        <!-- <bg-author></bg-author> -->

    </nb-layout-column>

    <!-- LAYOUT FOOTER (NOT VISIBLE) -->
    <nb-layout-footer fixed>

        <div class="d-flex justify-content-between w-100">
            <div>
                <a href="mailto:cliff.crerar@gmail.com">{{supportLink}}</a>
            </div>
            <div>
                Version: {{appVersion}}
            </div>
            <div>
                <a href="#">Privacy Policy</a>
            </div>
        </div>

    </nb-layout-footer>

</nb-layout>