<!-- ***************************************
TEMPLATE: Search input
*************************************** -->
<div class="d-flex justify-content-between w-100 align-items-center">
  <div>
    <h1
      style="font-family: Poiret One !important; font-weight: bold;"
      class="sub-header text-white w-100">{{subHeaderTitle}}</h1>
  </div>
  <div class="d-flex flex-row" class="animated slideInRight" *ngIf="showSearchLabel">
    <!-- <div>
      
    </div> -->
    <!-- <div> -->
      <label for="#search-contact">
        <span class="search-label" *ngIf="showHideSpyglass">
            <nb-icon icon="search-outline"></nb-icon>
        </span>
      </label>
      <input
      id="search-contact"
      #searchBox
      (focus)="onFocusEvent($event)"
      (blur)="onBlurEvent($event)"
      (keyup)="onKeyPress(searchBox)"
      type="text"
      nbInput
      fullWidth
      shape="round"
      placeholder>

    <!-- </div> -->
  </div>
</div>
