/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-nav-controls.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@nebular/theme/index.ngfactory";
import * as i3 from "@nebular/theme";
import * as i4 from "@angular/router";
import * as i5 from "./side-nav-controls.component";
import * as i6 from "../_services/display-data.service";
import * as i7 from "../_services/intercomp-comms.service";
import * as i8 from "../_services/toaster.service";
var styles_SideNavControlsComponent = [i0.styles];
var RenderType_SideNavControlsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideNavControlsComponent, data: {} });
export { RenderType_SideNavControlsComponent as RenderType_SideNavControlsComponent };
export function View_SideNavControlsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nb-menu", [], null, null, null, i2.View_NbMenuComponent_0, i2.RenderType_NbMenuComponent)), i1.ɵdid(1, 4440064, null, 0, i3.NbMenuComponent, [i3.NB_WINDOW, i3.ɵa, i4.Router], { items: [0, "items"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sideNavControls; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SideNavControlsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-nav-controls", [], null, null, null, View_SideNavControlsComponent_0, RenderType_SideNavControlsComponent)), i1.ɵdid(1, 114688, null, 0, i5.SideNavControlsComponent, [i6.AppDataService, i4.Router, i3.NbSidebarService, i7.InterComponentCommsService, i8.HaveSomeToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideNavControlsComponentNgFactory = i1.ɵccf("app-side-nav-controls", i5.SideNavControlsComponent, View_SideNavControlsComponent_Host_0, {}, {}, []);
export { SideNavControlsComponentNgFactory as SideNavControlsComponentNgFactory };
